import { currentBrand } from '../../config'
import { EXTERNAL_LINKS } from '../../config/external-links'
import type { BrandType } from '../../config/index.types'
import type { LanguageCode } from '../../helpers/i18n'
import { getPath } from '../../routing/get-path'
import { useCurrentLocale } from '../../translations/use-current-locale'

export type LinkItem = { nameI18nKey: string; href: string; isExternal?: boolean }

const PRICING_LINKS: Record<BrandType, Partial<Record<LanguageCode, string>>> = {
  qasa: {
    en: 'https://support.qasa.se/hc/en-us/articles/22955620204433',
    sv: 'https://support.qasa.se/hc/sv/articles/22955620204433',
  },
  qasa_finland: {
    fi: 'https://support.qasa.fi/hc/fi/articles/360042797611',
  },
  qasa_france: {},
  blocket: {
    sv: 'https://support.bostad.blocket.se/hc/sv/articles/22955763303953',
    en: 'https://support.bostad.blocket.se/hc/en-us/articles/22955763303953',
  },
  dotcom: {},
}

export const useFooterLinks = () => {
  const currentLanguage = useCurrentLocale()
  const pricingLink = PRICING_LINKS[currentBrand][currentLanguage]
  return {
    startPage: { nameI18nKey: 'start_page', href: '/' },
    findHome: { nameI18nKey: 'find_a_home', href: '/find-home' },
    findTenant: { nameI18nKey: 'find_tenant', href: getPath('findTenant') },
    findVacation: { nameI18nKey: 'find_vacation', href: getPath('findVacation') },
    myListings: { nameI18nKey: 'my_listings', href: getPath('myListings') },
    leases: { nameI18nKey: 'leases', href: getPath('leases') },
    messages: { nameI18nKey: 'messages', href: getPath('messages') },
    register: { nameI18nKey: 'register', href: getPath('signup') },
    login: { nameI18nKey: 'login', href: '/login' },
    logout: { nameI18nKey: 'logout', href: getPath('logout') },
    pricing: pricingLink ? { nameI18nKey: 'pricing', href: pricingLink, isExternal: true } : null,
    ourOffer: {
      nameI18nKey: 'our_offer',
      href: getPath('contentful', { slug: 'rental-agreements-for-renters' }),
    },
    housingRentals: {
      nameI18nKey: 'housing_rentals',
      href: getPath('firsthandContract'),
    },
    blog: { nameI18nKey: 'blog', href: 'https://qasa.se/blog', isExternal: true },
    careers: { nameI18nKey: 'careers', href: 'https://qasa.teamtailor.com', isExternal: true },
    help: { nameI18nKey: 'help', href: getPath('help') },
    sitemap: { nameI18nKey: 'sitemap', href: '/sitemap' },

    // EXTERNAL LINKS
    termsAndConditions: {
      nameI18nKey: 'terms_conditions',
      href: EXTERNAL_LINKS.termsAndConditionsUrl,
      isExternal: true,
    },
    pressMedia: {
      nameI18nKey: 'press_media',
      href: 'https://support.qasa.se/hc/en-us/articles/360000479128',
      isExternal: true,
    }, // TODO: Add to external links // Sahin 2023-02
    legalMentions: EXTERNAL_LINKS.legalMentions
      ? {
          nameI18nKey: 'legal_mentions',
          href: EXTERNAL_LINKS.legalMentions,
          isExternal: true,
        }
      : null,
    forCompanies: EXTERNAL_LINKS.forCompaniesSupportUrl
      ? {
          nameI18nKey: 'for_companies',
          href: EXTERNAL_LINKS.forCompaniesSupportUrl,
          isExternal: true,
        }
      : null,

    //BLOCKET LINKS
    blocket: { nameI18nKey: 'Blocket', href: 'https://www.blocket.se/', isExternal: true },
    blocketVehicles: {
      nameI18nKey: 'Blocket Fordon',
      href: 'https://www.blocket.se/fordon',
      isExternal: true,
    },
    blocketWork: { nameI18nKey: 'Blocket Jobb', href: 'https://jobb.blocket.se/', isExternal: true },
  } as const satisfies Record<string, LinkItem | null>
}
