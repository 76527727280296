import type { FC, ReactNode, SVGProps } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Stack } from '@qasa/qds-ui'
import { Dialog } from '@qasa/app/src/components/dialog'
import styled from '@emotion/styled'
import { Label } from '@qasa/ui'

import { BRAND_CONFIG } from '../../config/brand-configurations'
import { useCurrentLocale } from '../use-current-locale'
import { useChangeLocale } from '../use-change-locale'
import { EnglishFlag } from '../../assets/country-flags/english-flag'
import { SwedishFlag } from '../../assets/country-flags/swedish-flag'
import { NorweiganFlag } from '../../assets/country-flags/norweigan-flag'
import { FinnishFlag } from '../../assets/country-flags/finnish-flag'
import type { LanguageCode } from '../../helpers/i18n'
import { FrenchFlag } from '../../assets/country-flags/french-flag'

import { AVAILABLE_LOCALES } from './language-utils'

const LanguageButton = styled.button<{ isSelected: boolean }>(({ theme, isSelected }) => ({
  display: 'flex',
  width: '100%',
  height: 56,
  alignItems: 'center',
  gap: theme.sizes['4x'],
  paddingLeft: theme.sizes['5x'],
  paddingRight: theme.sizes['5x'],
  borderRadius: theme.sizes['4x'],
  backgroundColor: isSelected ? theme.colors.bg.inset : theme.colors.core.white,
  border: isSelected
    ? `2px solid ${theme.colors.border.defaultSelected}`
    : `1px solid ${theme.colors.border.default}`,
}))

const StyledLabel = styled(Label)({
  cursor: 'pointer',
})

const IconWrapper = styled.div(({ theme }) => ({
  border: `1px solid ${theme.colors.border.default}`,
  borderRadius: theme.radii.full,
}))

const countryFlags: Record<LanguageCode, FC<SVGProps<SVGSVGElement>>> = {
  en: EnglishFlag,
  sv: SwedishFlag,
  nb: NorweiganFlag,
  fi: FinnishFlag,
  fr: FrenchFlag,
}

type LanguageSwitchDialogProps = {
  /**
   * The trigger for the language switch.
   * Needs to forward its ref and props to a button element.
   */
  trigger: ReactNode
}

export function LanguageSwitchDialog({ trigger }: LanguageSwitchDialogProps) {
  const [isLanguageDialogOpen, setLanguageDialogOpen] = useState(false)
  const locale = useCurrentLocale()
  const changeLocale = useChangeLocale()
  const { t } = useTranslation('language_switch')

  const currentLanguage = AVAILABLE_LOCALES.find(({ languageCode }) => languageCode === locale)

  if (BRAND_CONFIG.allowedLanguages.length < 2 || !currentLanguage) {
    return null
  }

  const availableLanguages = AVAILABLE_LOCALES.filter(({ languageCode: code }) =>
    BRAND_CONFIG.allowedLanguages.includes(code),
  )
  // NOTE: We want the current language to be the first in the list
  const sortedLanguages = availableLanguages.sort((a, b) => {
    if (a.languageCode === currentLanguage.languageCode) return -1
    if (b.languageCode === currentLanguage.languageCode) return 1
    return 0
  })
  return (
    <Dialog size="sm" isOpen={isLanguageDialogOpen} onOpenChange={setLanguageDialogOpen} trigger={trigger}>
      <Dialog.Body>
        <Stack gap={'6x'} alignItems={'flex-start'}>
          <Dialog.Header>
            <Dialog.Title>{t('title')}</Dialog.Title>
          </Dialog.Header>
          <Stack as="ul" gap={'2x'} alignItems={'flex-start'} style={{ width: '100%' }}>
            {sortedLanguages.map(({ nativeName: name, languageCode: code, ietfCode }) => {
              const Flag = countryFlags[code]
              return (
                <li style={{ width: '100%' }} key={code}>
                  <LanguageButton
                    isSelected={currentLanguage.languageCode === code}
                    onClick={() => changeLocale(code)}
                    lang={ietfCode}
                  >
                    <IconWrapper>
                      <Flag width={24} />
                    </IconWrapper>
                    <StyledLabel>{name}</StyledLabel>
                  </LanguageButton>
                </li>
              )
            })}
          </Stack>
        </Stack>
      </Dialog.Body>
    </Dialog>
  )
}
